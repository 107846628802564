@font-face {
  font-family: Titillium;
  src: local('Titillium'), url(/src/fonts/Titillium.ttf) format('opentype');
}

@font-face {
  font-family: AlienWorld;
  src: local('AlienWorld'), url(/src/fonts/AlienWorld.ttf) format('opentype');
}

@font-face {
  font-family: CobaltAlien;
  src: local('CobaltAlien'), url(/src/fonts/CobaltAlien.otf) format('opentype');
}

body {
  background-image: url('/public/space-background.png');
}

.App {
  font-family: Titillium, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

img {
  display: block;
}

a:link {text-decoration: none;}
a:hover {text-decoration: underline; cursor: pointer}

a:link, a:visited {
  color: #61dafb;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.flexRow {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.gap0 {
  gap: 0px;
}

.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.alignStart {
  align-items: flex-start;
}

.darkBlueCard {
  padding: 20px;
  background-color: #1a203c;
  border-radius: 20px;
}

.pad10 {
  padding: 10px;
}

.walletButton {
  width: 150px;
  padding: 10px;
  border: solid #68e5e8 1px;
  border-radius: 20px;
  background-color: #444444;
  color: #68e5e8;
  font-weight: bold;
}
.walletButton:hover {
  background-color: #222222;
}
.walletButton:active {
  border: solid #68e5e8 2px;
}


.menuLink:link, .menuLink:visited {
  color: silver;
}

.menuItem {
  font-family: AlienWorld, sans-serif;
  font-size: 25px;
  color: silver;
  margin: 0px;
  padding: 9px 0px 14px 40px;
  border-bottom: solid darkslateblue 1px;
}

button.adminButton {
  width: 110px;
  padding: 10px;
  border-radius: 20px;
  background-color: #ff2200;
  color: black;
  font-weight: bold;
}

.adminButton:hover {
  background-color: black;
  color: white;
}

button.clearButton {
  padding: 8px 15px 8px 15px;
  border-radius: 20px;
  border-color: lightskyblue;
  background-color: lightblue;
  color: black;
  font-weight: bold;
}

.clearButton:hover {
  background-color: cornflowerblue;
  color: black;
}

button.playButton {
  width: 110px;
  padding: 10px;
  border-radius: 20px;
  background-color: lightblue;
  color: black;
  font-weight: bold;
}

.playButton:hover {
  background-color: black;
  color: white;
}

button.tabButton {
  width: 180px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  background-color: lightblue;
  color: black;
  font-weight: bold;
}

.tabButton:hover {
  background-color: black;
  color: white;
}

button.disabledButton {
  width: 110px;
  padding: 10px;
  border-radius: 20px;
  background-color: rebeccapurple;
  color: grey;
  font-weight: bold;
}

.gameInfo {
  padding-top: 10px;
  padding-bottom: 10px;
  /*border-bottom: solid #888888 1px;*/
}

.gameName {
  font-weight: bold;
  font-size: 25px;
  text-align: left;
}

.gameDesc {
  font-size: 20px;
  text-align: left;
}

.clock {
  font-size: 20px;
}

.phase {
  font-size: 25px;
  font-family: CobaltAlien;
  background: lightskyblue;
  border-radius: 4px;
  padding: 3px 20px 3px 20px;
  border: 3px solid grey;
}


/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.box {
  font-size: 20px;
  width: 500px;
  margin: 0 auto;
  color: #270238;
  background: lightskyblue;
  border-radius: 4px;
  padding: 20px;
  border: 3px solid #999;
  overflow: auto;
}

.hover_wrapper{
  display:flex;
  align-content:center;
  justify-content:center;

}
.hover_relavance{
  position:relative;
  top: -100px;
  left: -225px;
  padding:0 80px;
}

.hover_list{
  position:absolute;
  box-sizing: border-box;
  overflow-y:scroll;
  width: 450px;
  z-index:999;
  background:grey;
  padding:10px;
  border:1px solid grey;
  box-shadow: 0 0 2px 2px grey;
}

.close-icon {
  cursor: pointer;
  position: relative;
  padding: 0px 7px 5px 7px;
  top: -5px;
  left: 250px;
  background: #ededed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  z-index: 10;
}

.link-button {
  font-size: 20px;
  color: white;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}
.link-button:hover { text-decoration: underline; }

.link-blue {
  font-size: 15px;
  color: #61dafb;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}
.link-button:hover { text-decoration: underline; }

.link-dark {
  /*font-size: 15px;*/
  color: #0e38f6;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}
.link-button:hover { text-decoration: underline; }

.moneyInput {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  width: 150px;
  padding: 5px;
  border: solid 2px rebeccapurple;
  border-radius: 20px;
  background-color: #270238;
  color: white;
}

tr.tableRow:hover {
  color: lightgreen;
}

.imageContainer { position: relative; }
.imageContainer img { display: block; }
.imageContainer .imageOverlay { position: absolute; bottom:0; left:0; }

.newsItem {
  background-color: rgba(50,50,50,0.5);
  border: green solid 1px;
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  width: 95%;
}
/* -----------------animation--------------------- */
.battle-plr__card-animation {
  animation: vibration 1s linear .5s;
}
@keyframes vibration {
  0% {
    transform: translateX(-2px);
  }
  10% {
    transform: translateX(2px);
  }
  20% {
    transform: translateX(-2px);
    transform: translateY(-2px);
  }
  30% {
    transform: translateX(2px);
  }
  40% {
    transform: translateX(-2px);
    transform: translateY(2px);
  }
  50% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(-2px);
    transform: translateY(-2px);
  }
  70% {
    transform: translateX(2px);
  }
  80% {
    transform: translateX(-2px);
    transform: translateY(2px);
  }
  90% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(-2px);
    transform: translateY(0);
  }
}
/* ----------blood--------------- */
.battle-plr-animation__blood {
  width: 71px;
  height: 100px;
  background-size: contain;
  opacity: 0;
  background-image: url('./../../public/blood.png');
  animation: laserBlood 2.2s linear .4s; 
}
@keyframes laserBlood {
  0% {
    opacity: .2;
  }
  0% {
    opacity: .4;
  }
  100% {
    opacity: .3;
  }
}
/* ---------target------------- */
.battle-plr-animation__bullets {
  position: absolute;
  width: 23px;
  height: 23px;
  opacity: 0;
  z-index: 1;
  background-image: url('./../../public/blood.png');
  background-position: center;
  background-size: cover;
}
.battle-plr-animation__bullets-img {
  width: 23px;
  height: 23px;
}
.battle-plr-animation__bullets-1 {
  top: 16px;
  left: 17px;
  animation: bullets 2.4s linear .2s;
}
.battle-plr-animation__bullets-2 {
  top: 50px;
  left: 42px;
  animation: bullets 2s linear .6s;
}
.battle-plr-animation__bullets-3 {
  top: 43px;
  left: 12px;
  animation: bullets 1.6s linear 1s;
}
.battle-plr-animation__bullets-4 {
  top: 29px;
  left: 37px;
  animation: bullets 1.2s linear 1.4s;
}
@keyframes bullets {
  0% {
    opacity: 1;
    scale: 1;
  }
  3% {
    scale: 1.4;
  }
  20% {
    scale: 1;
  }
  100% {
    opacity: 1;
  }
}
/* -----wolverine------ */
.battle-plr-animation__wolverine {
  position: absolute;
  background: transparent;
  width: 60px;
  height: 50px;
}
.battle-plr-animation__wolverine-1 {
  top: 14px;
  left: 22px;
  transform: rotate(326deg);
  animation: wolverine 2s linear .3s 1; 
}
.battle-plr-animation__wolverine-2 {
  top: 18px;
  left: -9px;
  transform: rotate(-12deg);
  animation: wolverine 1.6s linear .7s 1; 
}
.battle-plr-animation__wolverine-line-1 {
  opacity: 0;
  stroke-dasharray: 401;
}
.battle-plr-animation__wolverine-line-2 {
  opacity: 0;
  stroke-dasharray: 431;
}
.battle-plr-animation__wolverine-line-11 {
  animation: wolverine1 2s linear .2s; 
}
.battle-plr-animation__wolverine-line-12 {
  animation: wolverine2 2s linear .2s; 
}
.battle-plr-animation__wolverine-line-13 {
  animation: wolverine1 2s linear .2s; 
}
.battle-plr-animation__wolverine-line-21 {
  animation: wolverine1 1.6s linear .7s; 
}
.battle-plr-animation__wolverine-line-22 {
  animation: wolverine2 1.6s linear .7s; 
}
.battle-plr-animation__wolverine-line-23 {
  animation: wolverine1 1.6s linear .7s; 
}
@keyframes wolverine {
  0% {
    opacity: 1;
    scale: 1;
  }
  50% {
    scale: 1.3;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
@keyframes wolverine1 {
  0% {
    opacity: 1;
    stroke-dashoffset: 401;
  }
  80% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}
@keyframes wolverine2 {
  0% {
    stroke-dashoffset: 431;
    opacity: 1;
  }
  80% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}
/* --------------laser----------- */
.battle-plr-animation__laser-hole {
  position: absolute;
  opacity: 0;
}
.battle-plr-animation__laser-hole-1 {
  top: 10px;
  left: 46px;
  animation: laserHole 2.1s linear .35s; 
}
.battle-plr-animation__laser-hole-2 {
  top: 50px;
  left: 20px;
  animation: laserHole 1.7s linear .75s; 
}
.battle-plr-animation__laser-hole-3 {
  top: 15px;
  left: 25px;
  animation: laserHole 1.3s linear 1.15s; 
}
.battle-plr-animation__laser-hole-4 {
  top: 40px;
  left: 30px;
  animation: laserHole .9s linear 1.55s; 
}
.battle-plr-animation__laser {
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
}
.battle-plr-animation__laser-1 {
  top: 64px;
  left: 56px;
  transform: rotate(23deg);
  animation: laser1 .4s linear; 
}
.battle-plr-animation__laser-2 {
  top: 87px;
  left: -13px;
  transform: rotate(67deg);
  animation: laser2 .4s linear .4s; 
}
.battle-plr-animation__laser-3 {
  top: 62px;
  left: -22px;
  transform: rotate(73deg);
  animation: laser3 .4s linear .8s; 
}
.battle-plr-animation__laser-4 {
  top: 80px;
  left: 60px;
  transform: rotate(12deg);
  animation: laser4 .4s linear 1.2s; 
}
@keyframes laserHole {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
} 
@keyframes laser1 {
  0% {
    opacity: 1;
  }
  80% {
    height: 27%;
    width: 27%;
  }
  100% {
    height: 27%;
    width: 27%;
    opacity: 1;
    top: 16px;
    left: 52px;
  }
} 
@keyframes laser2 {
  0% {
    opacity: 1;
  }
  80% {
    height: 27%;
    width: 27%;
  }
  100% {
    width: 27%;
    height: 27%;
    opacity: 1;
    top: 56px;
    left: 18px;
  }
} 
@keyframes laser3 {
  0% {
    opacity: 1;
  }
  80% {
    height: 27%;
    width: 27%;
  }
  100% {
    width: 27%;
    height: 27%;
    opacity: 1;
    top: 22px;
    left: 21px;
  }
} 
@keyframes laser4 {
  0% {
    opacity: 1;
  }
  80% {
    height: 27%;
    width: 27%;
  }
  100% {
    height: 27%;
    width: 27%;
    opacity: 1;
    top: 46px;
    left: 38px;
  }
} 